[data-scroll]{

  transition: opacity 0.5s, transform 0.5s;
  transform: translate3d(0,30px,0);
  opacity: 0;

  &.viewed{

    transform: translate3d(0,0,0);
    opacity: 1;

  }

}