body{

  font-family: $font-text;
  font-weight: 200;
  color: #000;

}

p{

  line-height: 1.625;

}

strong{

  font-weight: bold;

}


.TitleHero{

  font-size: rem(50px);
  text-transform: lowercase;
  line-height: 0.88;

  @include mq(l-tablet){
    font-size: rem(90px);
  }

  @include mq(desktop){

    font-size: rem(120px);

  }

}

%title{

  font-size: rem(45px);
  text-transform: lowercase;
  line-height: 0.88;

  @include mq(s-tablet){
    font-size: rem(90px);
  }

  &2,
  %title2{

    font-size: rem(30px);
    line-height: 1.2;
    margin: 0.5em 0;
    @include mq(s-tablet){
      font-size: rem(50px);
    }

  }

}

%subtitle{

  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;

}

h1,
.Title{

  @extend %title;

  &__underline{
    border-bottom: 1px solid #000;
  }

}

h2,
.Title2{

  @extend %title;
  @extend %title2;

  @include mq(s-tablet) {
    padding: 0 10% 0 5%;
  }

  @include mq(l-tablet){
    padding: 0 15% 0 5%;
  }

}

h3,
.Title3{

  @extend %subtitle;

}

.Subtitle,
.Link{

  @extend %subtitle;

}

.Container--news{

  p{

    font-size: 24px;
    line-height: 1.66;
    padding-left: 5%;
    padding-right: 5%;
    text-transform: lowercase;

  }


  img{

    margin: 30px -5%;
    width: 110%;
    height: auto;

  }

  ul,
  ol{

    margin-left: 7%;
    margin-right: 7%;
    padding: 0;

    li{

      padding-left: 10px;

    }

  }

  ul{

    list-style: disc;

  }

  a{

    border-bottom: 2px solid #000;
    color: rgba(#000, 0.5);
    transition: color 0.3s;

    .no-touchevents &:hover{

      border-bottom: 4px solid #000;

    }

  }

}