.home-slider{

  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  perspective: 1200px;

}
.SectionSlider{

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%,-50%,0);
  width: 80vw;
  height: 80vh;

  &.__fullscreen{

    width: 100vw;
    height: 100vh;

  }

  &.__draggable{

    cursor: move;
    cursor: url(../images/icons/grab.cur);
    cursor: -webkit-grab;
    cursor:    -moz-grab;
    cursor:         grab;

  }

  &.__dragging{

    cursor: url(../images/icons/grabbing.cur);
    cursor: -webkit-grabbing;
    cursor:    -moz-grabbing;
    cursor:         grabbing;

  }


  &__Container{

    width: 700%;
    height: 100%;
    transform-style: preserve-3d;
    position: absolute;
    left: 0;

    > div:last-child{
      margin-right: 0;
    }

  }

  &__Item{

    width: calc(100% / 7);
    height: 100%;
    backface-visibility: hidden;
    position: relative;
    float: left;
    //transition: all 0.8s $ease1;
    overflow: hidden;
    transform-style: preserve-3d;

    &Bg{

      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      //transition: transform 0.8s $ease1;
      transform: translate3d(-50%,-50%,0) scale(0.9);
      z-index: 1;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;

      &:before {

        background: rgba(#000000, 0.2);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: "";
        opacity: 0;
        transition: opacity 0.5s;
        z-index: 5;

        .__fullscreen & {

          opacity: 1;

        }

      }

      .__fullscreen &{

        transform: translate3d(-50%,-50%,0) scale(1);

      }

      > div{

        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        transition: transform 0.8s $ease1;
        transform: translate3d(-50%,-50%,0);
        z-index: 1;

      }

    }

    &Text{

      position: absolute;
      left: 0vw;
      bottom: 50%;
      z-index: 5;
      backface-visibility: hidden;
      transform: translate3d(0,50%,0);
      //overflow: hidden;
      overflow-y: hidden;
      @include mq(l-tablet){
        max-width: 50%;
      }

      .__fullscreen &{

        left: 10vw;
        //bottom: 15%;

      }

      .Subtitle{

        opacity: 0;

        .__fullscreen &{

          color: #fff;

        }

      }

      .TitleHero{

        padding-left: 2vw;
        position: relative;
        padding-bottom: 30px;
        transform: translateY(100%);

        .__fullscreen &{

          color: #fff;

        }

        //&:after{
        //
        //  position: absolute;
        //  left: 0;
        //  bottom: -30px;
        //  width: 100%;
        //  height: 3px;
        //  background: #000;
        //  content: "";
        //
        //  .__fullscreen &{
        //
        //    background: #fff;
        //
        //  }
        //
        //
        //}

      }

      .TitleLine{

        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 3px;
        background: #000;
        content: "";

        .__fullscreen &{

          background: #fff;

        }

      }

      .Subtitle{

        padding-left: 2vw;
        margin-bottom: 1vw

      }

      .Link{

        text-align: right;
        display: block;

        .__fullscreen &{

          opacity: 0;
          visibility: hidden;

        }

      }


    }

    &Link{

      position: absolute;
      right: 30vw;
      z-index: 20;
      bottom: 5vw;
      //border-left: 2px solid #000;
      //padding-top: 100px;
      backface-visibility: hidden;
      white-space: nowrap;
      cursor: default;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s;

      @include mq(s-tablet){
        bottom: 0;
        right: 15vw;
      }

      &:before{

        width: 2px;
        height: 100px;
        background: #000;
        content: "";
        position: absolute;
        left: -20px;
        bottom: 0;
        transition: height 0.3s;

      }

      .__fullscreen &{

        bottom: -5vh;
        text-indent: -9999px;


      }

      .show-fullscreen &{

        opacity: 1;
        visibility: visible;

      }

      &.Link{
        .show-fullscreen &:hover{

          &:before{
            height: 120px;
          }

        }
      }


      &--scroll{

        opacity: 1;
        visibility: visible;
        text-indent: 0!important;
        bottom: 0!important;
        right: auto;
        left: 70%;
        width: 30%;
        padding-bottom: 100px;
        @extend %subtitle;
        white-space: normal;
        color: #fff;

        @include mq(s-tablet){
          left: 80%;
          width: auto;
        }

        &:before{

          bottom: auto;
          top: 0;
          height: 100%;
          background: #fff;

        }

        .show-fullscreen &{

          opacity: 0;
          visibility: hidden;

        }

      }

    }

  }

}

.bgs{

  &--azienda{
    @include background-center();
    background-image: url(../images/page-header/azienda.jpg);
  }

  &--prodotti{
    @include background-center();
    background-image: url(../images/page-header/prodotti.jpg);
  }

  &--tessili-casa{
    @include background-center();
    background-image: url(../images/page-header/tessili-casa.jpg);
  }

  &--linea-alberghiera{
    @include background-center();
    background-image: url(../images/page-header/linea-alberghiera.jpg);
  }

  &--tessuti-tecnici-protettivi{
    @include background-center();
    background-image: url(../images/page-header/tessuti-tecnici-protettivi.jpg);
  }

  &--valore-aggiunto{
    @include background-center();
    background-image: url(../images/page-header/valore-aggiunto.jpg);
  }

  &--news{
    @include background-center();
    background-image: url(../images/page-header/news.jpg);
  }

}