#main-logo{

  width: 120px;
  height: 19px;
  position: fixed;
  top: 4vh;
  left: 3vw;
  z-index: 200;
  transition: fill 0.5s;
  transform: translate3d(0,0,0);
  cursor: pointer;

  //.touchevents & {

    //transform: translate3d(30px,0,0)!important;

  //}

  @include mq(false, l-tablet){

    transform: translate3d(0,0,0)!important;

  }

    .on-page &{

    transform: translate3d(100px,0,0);
    //transition: all 0.5s;
    fill: #fff;

  }

  .show-menu &{

    //transform: translate3d(0px,0,0);
    fill: #fff!important;

  }

  .scrolled &,
  .show-fullscreen &{

    fill: #000;

  }

}

#menu-button{

  width: 20px;
  height: 19px;
  position: fixed;
  top: 4vh;
  right: 3vw;
  border: none;
  appearance: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
  z-index: 200;

  span{

    text-indent: -9999px;
    display: inline-block;
    height: 3px;
    background: #000;
    width: 100%;
    vertical-align: middle;
    position: relative;
    transition: background 0.3s;

    .on-page &{
      background: #fff;
    }

    .scrolled &,
    .show-fullscreen &{

      background: #000;

    }

    .show-menu &{
      background: transparent!important;
    }

    &:before,
    &:after{

      display: block;
      width: 20px;
      height: 3px;
      background: #000;
      content: "";
      position: absolute;
      top: -3px;
      left: 50%;
      transform: translate(-50%,-5px);
      transition: all 0.3s $ease1;

      .show-menu &{
        background: #fff!important;
        transform: translate(-11px,3px) rotate(45deg)!important;
      }

      .on-page &{
        background: #fff;
      }

      .scrolled &,
      .show-fullscreen &{

        background: #000;

      }

    }

    &:after{

      top: 3px;
      transform: translate(-50%, 5px);

      .show-menu &{
        transform: translate(-50%,-3px) rotate(-45deg)!important;
      }

    }

  }

  .no-touchevents body:not(.show-menu) &:hover{

    span:before{
      width: 12px;
    }

    span:after{
      width: 10px;
    }

  }


}