.Form{

  margin: auto;

  .Row{

    margin-top: 0;
    margin-bottom: 0;

    &__Col,
    &__OneCol{

      margin-top: 10px;
      margin-bottom: 10px;

      @include mq(s-tablet){
        margin-top: 30px;
        margin-bottom: 30px;
      }

    }

  }

  width: 100%;

  &__Label{

    font-size: 18px;
    font-weight: 200;
    color: #fff;

    @include mq(s-tablet){
      font-size: 24px;
    }

  }

  &__Input{

    border: none;
    border-bottom: 2px solid rgba(#fff,0.1);
    background: none;
    padding: 10px 0;
    font-size: 18px;
    font-weight: 200;
    width: 100%;
    box-shadow: none;
    color: #fff;
    transition: border 0.3s;
    text-transform: lowercase;

    @include mq(s-tablet){
      font-size: 24px;
    }

    .Input__Wrap.Input--focused &{
      border-bottom-color: #fff;
    }

    .Input__Wrap.Input--not-valid &{
      border-bottom-color: red;
    }

    @include placeholder{
      color: rgba(#fff, 0.2);
    }

  }

  &__Textarea{

    border: none;
    border-bottom: 2px solid rgba(#fff,0.1);
    background: none;
    padding: 10px 0;
    font-size: 18px;
    font-weight: 200;
    width: 100%;
    box-shadow: none;
    color: #fff;
    transition: border 0.3s;
    text-transform: lowercase;
    resize: none;

    @include mq(s-tablet){
      font-size: 24px;
    }

    .Input__Wrap.Input--focused &{
      border-bottom-color: #fff;
    }

    .Input__Wrap.Input--not-valid &{
      border-bottom-color: red;
    }

    @include placeholder{
      color: rgba(#fff, 0.2);
    }

  }

  &__Submit{

    font-size: 18px;
    font-weight: 200;
    color: #fff;
    display: inline-block;
    padding: 10px 20px;
    background: none;
    border: none;
    border-bottom: 1px solid #fff;
    cursor: pointer;
    text-transform: lowercase;
    transition: all 0.3s;

    .message-sent &{
      opacity: 0;
      visibility: hidden;
    }

    @include mq(s-tablet){
      font-size: 24px;
    }

    &Col{

      position: relative;

      @include mq(false, desktop){
        width: 100%!important;
        text-align: left;
        margin-top: 30px!important;
      }
      @include mq(desktop){
        width: 50%!important;
        text-align: right;
      }

    }

  }

  &__Privacy{

    @extend %subtitle;
    position: relative;
    padding-left: 40px;

    &Col{

      text-align: left;


      @include mq(false, desktop){
        width: 100%!important;
        margin-bottom: 30px!important;;
      }
      @include mq(desktop){
        width: 50%!important;
      }

      input{
        display: none;
        &:checked ~ label:after{
          opacity: 1;
        }
      }

    }

    &:before{

      width: 20px;
      height: 20px;
      border-radius: 50%;
      content: "";
      background: #1a1a1a;
      position: absolute;
      top: 50%;
      margin-top: -10px;
      left: 0;
      border: 2px solid #1a1a1a;

      .Input--not-valid &{

        border: 2px solid red;

      }

    }

    &:after{

      width: 5px;
      height: 5px;
      border-radius: 50%;
      content: "";
      background: #fff;
      position: absolute;
      top: 50%;
      margin-top: -2.5px;
      left: 8px;
      opacity: 0;
      transition: opacity 0.3s;

    }

    a{

      border-bottom: 2px solid #fff;

    }

  }

  &__Response{

    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    padding: 0 40px 0 0;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;

    .message-sent &{
      opacity: 1;
      visibility: visible;
    }

  }

}

.Input__Wrap{

  position: relative;
  .Form__Label{

    position: absolute;
    top: 10px;
    left: 0;
    transition: transform 0.3s;

  }

  &.Input--focused {

    .Form__Label{

      transform: translateY(-30px);
      @extend %subtitle;

    }

  }


}