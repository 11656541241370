.Contacts{

  position: absolute;
  top: 0;
  left: 0;
  display: none;
  color: #fff;
  opacity: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  align-items: center;
  padding: 30px 0;
  -webkit-overflow-scrolling: touch;

  .Container{

    margin: auto;
    width: 90%;
    max-width: none!important;

    > .Row > :first-child .Row{

      margin-top: 20px;
      margin-bottom: 10vh;

      .Row__ColLarge{

        @include mq(l-tablet){
          white-space: nowrap;
        }

      }

    }
    > .Row > :last-child .Row{

      margin-top: 20px;
      margin-bottom: 20px;

      .Row__Col{
        padding-left: 0;
        padding-right: 0;
      }

      .Row__OneCol{

        padding-left: 0;
        padding-right: 0;
        @include mq(l-tablet){
          white-space: nowrap;
        }

      }

    }

  }



}

