.Footer{

  .Title2{

    padding: 0 50px 15px 25px;
    display: inline-block;

  }

  &__Text{

    display: flex;
    flex-wrap: wrap;
    width: 100%;
    font-size: 14px;

    @include mq(l-tablet) {
      padding-left: 20%;
    }

    &Col{

      width: 100%;
      padding-right: 20px;

      @include mq(s-tablet){
        width: 50%;
      }

      @include mq(l-tablet){
        width: auto;
        flex: 1;
      }

      p{

        line-height: 1.86;

      }

    }

  }

  &__Fixed{
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    z-index: 100;
    width: 100%;
    padding: 0 3vw 15px 3vw;
    font-size: 14px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
    color: rgba(#000,0.5);

    @include mq(false, s-tablet){

      display: none;

    }

    .show-fullscreen &{

      opacity: 1;
      visibility: visible;

    }

    &Left{

      width: 50%;

    }

    &Right{
      width: 50%;
      text-align: right;
    }

  }

}

.nowrap{
  white-space: nowrap;
}