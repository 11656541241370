.Button{

  @extend %subtitle;
  padding: 15px 20px;
  position: relative;
  margin-top: 20px;
  display: inline-block;
  transition-delay: 0.15s;

  &:before{

    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 3px;
    background: #000;
    content: "";
    z-index: -1;
    transition: height 0.3s $ease1;

  }

  .no-touchevents &:hover{

    color: #fff;

    &:before{

      height: 100%;

    }

  }

}