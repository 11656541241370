.show-menu{

  overflow: hidden;

}

.Menu{

  position: fixed;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background: #000;
  z-index: 100;
  overflow: hidden;
  display: none;

  &__Inner{

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .show-contacts &{

      display: none;

    }

    a{

      color: rgba(#fff, 0.2);
      font-size: rem(30px);
      text-transform: lowercase;
      transition: color 0.3s;

      @include mq(s-tablet){
        font-size: rem(50px);
      }

      &.__active,
      .no-touchevents &:hover{

        color: #fff;

      }

    }


  }

  .Subtitle{

    color: #fff;
    font-size: 10px;

  }


  &__Nav{

    position: absolute;
    top: 20vh;
    left: 10vw;

  }

  &__Reserved{

    position: absolute;
    left: 10vw;
    bottom: 130px;

    @media (orientation: landscape) and (max-width: 767px){

      right: 10vw;
      left: auto;
      bottom: 5vh;
      text-align: right;

    }

    @include mq(s-tablet){

      left: 10vw;
      bottom: 5vh;

    }

    .Subtitle{

      @media (orientation: landscape) and (max-width: 767px){

        margin-top: 25px;

      }

    }

  }

  &__Languages{

    position: absolute;
    left: 10vw;
    bottom: 5vh;

    @include mq(s-tablet){

      right: 10vw;
      left: auto;

    }

    &Point{

      color: rgba(#fff, 0.2);
      font-size: rem(50px);
      padding: 0 10px;

    }

  }

}