$cookie-text-font-size: 12px;

$cookie-bar-max-width: 1000px;
$cookie-bar-max-width-wide: 1280px;

.cookie-bar {

  position: fixed;

  bottom: 0;
  left: 0;

  width: 100%;

  z-index: 500;

  background: rgba(#000, 0.9);

  p{
    color: rgba(#fff,0.6);
  }

  &__content {

    @include clearfix();

    max-width: $cookie-bar-max-width;
    margin: 0 auto;

    padding: 2rem 5%;
    color: #fff;

    @include mq(s-tablet){
      padding: 3rem 5%;
    }

    @include mq(wide) {
      max-width: $cookie-bar-max-width-wide;
    }
  }

  a {

    color: rgba(#fff,0.6);
    text-decoration: underline;

    @at-root .no-touchevents &:hover {

      color: rgba(#fff,1);
    }
  }


  &__text {


    font-size: $cookie-text-font-size;
    letter-spacing: .25em;

    // line-height: 1.5em;
    line-height: 2em;

    margin: 0;

    @include mq(l-tablet) {

      width: 60%;
      float: left;

      max-width: 700px;
      margin: 0 auto;

      // padding: .6em 0;

    }
  }

  &__accept-outer {

    text-align: center;
    padding-top: 2rem;

    @include mq(l-tablet) {

      width: 40%;
      float: left;

      padding-top: 0;

      text-align: right;

    }
  }

  &__accept-button {

    $color-button: color(gray--light);

    display: inline-block;
    border: 1px solid rgba(#fff,0.5);
    background: transparent;
    color: rgba(#fff,0.5);
    padding: .9rem 6rem;
    font-size: $cookie-text-font-size;

    text-transform: uppercase;
    letter-spacing: .25em;

    transition: all .25s ease-out;

    @at-root .no-touchevents &:hover {

      border-color: #fff;
      color: #fff;

    }

  }

}