$font-size-default: 16px !default;
$font-base-path: '/assets/fonts/' !default;

$font-sizes: (

) !default;

$fonts: (
    akzidenz-grotesk-bq: (
        ("Akzidenz Grotesk BQ", akzidenz-grotesk-bq-light, 200),
        ("Akzidenz Grotesk BQ", akzidenz-grotesk-bq-bold, bold),
    ),
) !default;

$font-text: "Akzidenz Grotesk BQ";

@each $font in map-keys($fonts) {

    @each $font-family in map-deep-get($fonts, $font) {

        @font-face {

            font-family: '#{nth($font-family,1)}';
            font-weight: #{nth($font-family,3)};
            src:
                    //url('../fonts/#{$font}/#{nth($font-family,2)}/#{nth($font-family,2)}.eot?#iefix') format('embedded-opentype'),
                    url('../fonts/#{$font}/#{nth($font-family,2)}/#{nth($font-family,2)}.woff2') format('woff2'),
                    url('../fonts/#{$font}/#{nth($font-family,2)}/#{nth($font-family,2)}.woff') format('woff');
            //url('../fonts/#{$font}/#{nth($font-family,2)}/#{nth($font-family,2)}.ttf') format('truetype'),
            //url('../fonts/#{$font}/#{nth($font-family,2)}/#{nth($font-family,2)}.svg##{nth($font-family,2)}') format('svg');
        }

    }
}